/*
* ROI range slider widget
*/
document.addEventListener('DOMContentLoaded', function() {

	if(document.getElementById('rangeSliderInput')){

		const slider = document.getElementById('rangeSliderInput');
		const ordersPanel = document.getElementById('ordersPanel');
		const ordersPanelValue = ordersPanel.querySelector('span'); 
		const revenuePanel = document.getElementById('revenuePanel');
		const revenuePanelValue = document.getElementById('revenuePanelValue');
		const thumbWidth = 16;
		
		slider.addEventListener('input', function() {

			// Use the raw number value for calculations
			let rawOrdersValue = parseInt(this.value);
			let displayOrdersValue = rawOrdersValue;

			// If the value is at the maximum, display "10k+"
			if (rawOrdersValue == parseInt(this.max)) {
				displayOrdersValue = '10k+';
			} else {
				// Format the value with commas for display
				displayOrdersValue = rawOrdersValue.toLocaleString();
			}

			// Update the orders panel value
			ordersPanelValue.textContent = displayOrdersValue;
			
			///////// Calculate the corresponding revenue value
			// Hardcoded figures
			var averageOrderValue = 50;
			var annualPurchaseFrequency = 2;
			var lifetimePurchases = 4;
			var percentageAnnualSpendRedeemerVersusShopper = 1.6445;
			var percentageAnnualSpendMemberVersusShopper = 0.3997;
			var percentageOfShoppersBecomingMembers = 0.3446;
			var successfullyReferredCustomersAnnually = 250;
			var percentageOfMembersBecomingRedeemers = 0.2324;

			var annualSpendAllShoppers = averageOrderValue * annualPurchaseFrequency;
			var annualSpendRedeemingMember = annualSpendAllShoppers * ( 1 + percentageAnnualSpendRedeemerVersusShopper);
			var annualSpendMember = annualSpendAllShoppers * ( 1 + percentageAnnualSpendMemberVersusShopper);
			var shoppers = (rawOrdersValue / annualPurchaseFrequency ) * 12;
			var members = shoppers * percentageOfShoppersBecomingMembers;
			var newRedeemingMembersPerYear = members * percentageOfMembersBecomingRedeemers;
			var revenueCoreLoyalty = (annualSpendRedeemingMember - annualSpendMember) * newRedeemingMembersPerYear;
			var revenueCoreReferrals = successfullyReferredCustomersAnnually * averageOrderValue * lifetimePurchases;
			
			let rawRevenueValue = revenueCoreLoyalty + revenueCoreReferrals;
			let roundedRevenueValue = Math.round(rawRevenueValue); // Round to nearest whole number
			let displayRevenueValue = roundedRevenueValue.toLocaleString();

			// Update the revenue panel value
			revenuePanelValue.textContent = displayRevenueValue;

			///////// Positioning the order+revenue panels
			// Calculate percentage using raw numeric value
			const percent = (rawOrdersValue - this.min) / (this.max - this.min); 

			// Update slider background gradient to create the filled track
			const purple = '#582c83'; // Color of the filled part
			const grey = '#bdbdbd'; // Color of the track
			const background = `linear-gradient(to right, ${purple} ${percent * 100}%, ${grey} ${percent * 100}%)`;
			this.style.background = background;

			// Recalculate offset for ordersPanel using percentage, center the panel, and account for thumb width
			const sliderWidth = this.offsetWidth;
			const ordersPanelWidth = ordersPanel.offsetWidth;
			const offsetOrdersPanel = percent * (sliderWidth - thumbWidth) - ordersPanelWidth / 2 + thumbWidth / 2;

			ordersPanel.style.left = `${offsetOrdersPanel}px`;

			// Recalculate offset for revenuePanel using the same percentage, center the panel, and account for thumb width
			const revenuePanelWidth = revenuePanel.offsetWidth;
			const offsetRevenuePanel = percent * (sliderWidth - thumbWidth) - revenuePanelWidth / 2 + thumbWidth / 2;

			revenuePanel.style.left = `${offsetRevenuePanel}px`;
		});

	}
	
});

